/** ошибка, которая отображается в крайнем случае */
export const DEFAULT_ERROR = 'DefaultException';

/** сообщения об ошибках ввода */
export enum ERROR_MESSAGE {
  AuthCountException = 'Неправильный номер договора и/или пароль',
  ContractNotServicedException = 'Договор не обслуживается',
  ContractNotFoundException = 'Договор не найден',
  BlockException = 'Доступ в\u00A0раздел «Моя\u00A0Планета» временно заблокирован',
  ArgumentNullException = 'Введите пароль',
  DefaultException = 'Что-то пошло не так',
}

export enum RESPONSE_STATUS {
  status400 = 400,
  status500 = 500,
}

/** Название сп для открытия после авторизации */
export enum SIDE_PAGES {
  PAYMENT = 'payment',
  IPv6 = 'ipv6',
  /** Приостановка обслуживания */
  SUSPENSION_OF_SERVICE = 'suspension',
  /** Возобновление обслуживания */
  RESUMPTION_OF_SERVICE = 'resumption',
  /** Доступ в ЛК без пароля */
  ACCESS_WITHOUT_PASSWORD = 'accessWithoutPassord',
  /** Изменить пароль */
  CHANGE_PASSWORD = 'changePassword',
  /** Изменить пин-код */
  CHANGE_PIN = 'changePin',
  /** Изменить уведомления в контактах */
  CHANGE_NOTIFICATION = 'changeNotification',
  /** Удалить контакст */
  DELETE_CONTACT = 'deleteContact',
  /** Добавить контакт */
  ADD_CONTACT = 'addContact',
  /** Подтвердить контакт */
  VERIFY_CONTACT = 'verifyContact',
  /** Настройки тихого часа */
  CHANGE_MUTE = 'changeMute',
  DEVICES = 'devices',
  /** Гарантия+ */
  WARRANTY = 'warranty',
  /** Возврат оборудования */
  REGRESS_DEVICE = 'regress',
}

/** Состояние сп при открытии после авторизации */
export enum STATE_SP {
  /** Открыть соглашение */
  AGREEMENT,
  /** Вызов метода(действие) при открытии сп */
  ACTION,
  /** Открыть сп */
  REOPEN,
}

export const OPEN_PAYMENT_SP = { [SIDE_PAGES.PAYMENT]: STATE_SP.ACTION };
export const OPEN_IPV6_SP_ACTION = { [SIDE_PAGES.IPv6]: STATE_SP.ACTION };
export const OPEN_IPV6_SP_AGREEMENT = { [SIDE_PAGES.IPv6]: STATE_SP.AGREEMENT };
export const OPEN_SUSPENDING_ACTION = {
  [SIDE_PAGES.SUSPENSION_OF_SERVICE]: STATE_SP.ACTION,
};
export const OPEN_SUSPENDING_AGREEMENT = {
  [SIDE_PAGES.SUSPENSION_OF_SERVICE]: STATE_SP.AGREEMENT,
};
export const OPEN_RESUMPTION_ACTION = {
  [SIDE_PAGES.SUSPENSION_OF_SERVICE]: STATE_SP.ACTION,
};
export const OPEN_ACCESS_WITHOUT_PASSWORD_ACTION = {
  [SIDE_PAGES.ACCESS_WITHOUT_PASSWORD]: STATE_SP.ACTION,
};
export const OPEN_CHANGE_PASSWORD_ACTION = {
  [SIDE_PAGES.CHANGE_PASSWORD]: STATE_SP.ACTION,
};
export const OPEN_CHANGE_PIN_ACTION = {
  [SIDE_PAGES.CHANGE_PIN]: STATE_SP.ACTION,
};
export const OPEN_CHANGE_NOTIFICATION = {
  [SIDE_PAGES.CHANGE_NOTIFICATION]: STATE_SP.ACTION,
};
export const OPEN_DELETE_CONTACT = {
  [SIDE_PAGES.DELETE_CONTACT]: STATE_SP.ACTION,
};
export const OPEN_ADD_CONTACT = {
  [SIDE_PAGES.ADD_CONTACT]: STATE_SP.ACTION,
};
export const OPEN_VERIFY_CONTACT = {
  [SIDE_PAGES.VERIFY_CONTACT]: STATE_SP.ACTION,
};
export const OPEN_CHANGE_MUTE = {
  [SIDE_PAGES.CHANGE_MUTE]: STATE_SP.ACTION,
};
