import { flow, types } from 'mobx-state-tree';
import { toJS } from 'mobx';
/** api */
import { getSubscriptionInfos } from '~/api/api';
import createApiPathModel, {
  defaultModelState,
} from './models/createApiPathModel';
import {
  ShippingsForSubscriptionsModel,
  SoftlineSubscriptionsModel,
} from './models/SoftlineModel';

const RequestsStateModel = types.model('State', {
  getSubscriptionsInfos: createApiPathModel('GET /Tax/Tax/GetTariffHistory'),
});

export const SoftlineStoreModel = types
  .model('SoftlineStore', {
    requestsState: RequestsStateModel,
    shippingsForSubscriptionsData: types.array(ShippingsForSubscriptionsModel),
    softlineSubscriptionsData: types.array(SoftlineSubscriptionsModel),
  })
  .views((self) => ({
    get softlineListAuthClient() {
      if (toJS(self.softlineSubscriptionsData).length === 0) return [];
      return toJS(self.softlineSubscriptionsData).filter(
        (item) => item?.statusString,
      );
    },
    get isFetchedSubscriptionsInfos() {
      return self.requestsState.getSubscriptionsInfos.isFetched;
    },
    get softlineSubscriptions() {
      return toJS(self.softlineSubscriptionsData);
    },
    get shippingsForSubscriptions() {
      return toJS(self.shippingsForSubscriptionsData);
    },
    get isLoadingSoftlineSubscriptions() {
      return self.requestsState.getSubscriptionsInfos.isLoading;
    },
    get isFewSubscriptions() {
      return (
        toJS(
          self.softlineSubscriptionsData.filter((item) => item?.statusString),
        ).length <= 1
      );
    },
  }))
  .actions((self) => ({
    setSoftlineSubscriptions: (softlineSubscriptions) => {
      self.softlineSubscriptionsData = softlineSubscriptions;
    },
    setShippingsForSubscriptions: (shippingsForSubscriptionsData) => {
      self.shippingsForSubscriptionsData = shippingsForSubscriptionsData;
    },
    resetSoftline: () => {
      self.shippingsForSubscriptionsData.clear();
      self.shippingsForSubscriptionsData.clear();
      self.softlineSubscriptionsData.clear();
      self.requestsState.getSubscriptionsInfos.reset();
    },
  }))
  .actions((self) => ({
    getSubscriptionInfos: flow(function* () {
      self.requestsState.getSubscriptionsInfos.reset();
      self.requestsState.getSubscriptionsInfos.setLoading();
      try {
        const res = yield getSubscriptionInfos();
        self.softlineSubscriptionsData = res;
        self.requestsState.getSubscriptionsInfos.setSuccess();
      } catch (e) {
        self.requestsState.getSubscriptionsInfos.setFail();
      }
    }),
  }));

export const SoftlineStoreInstance = SoftlineStoreModel.create({
  requestsState: {
    getSubscriptionsInfos: defaultModelState,
  },
  shippingsForSubscriptionsData: [],
  softlineSubscriptionsData: [],
});
