export enum PAYMENT_METHOD {
  card,
  sbp,
}

export enum RESULT_STATE {
  /** Предложение о привязке счёта сбп */
  SbpBindingSuggestion,
  /** Предложение об автоплатеже */
  AutopaymentSuggestion,
  /** Ожидание привязки */
  WaitingForBinding,
  /** Ожидание оплаты */
  WaitingForPayment,
  /** Оплата картой не прошла */
  CardPaymentFailed,
  /** Ошибка привязки сбп счёта  */
  SbpBindingFailed,
  /** Ошибка оплаты привязанным сбп счётом  */
  SbpPaymentFailed,
  /** Ошибка привязанного сбп счёта  */
  SbpBoundFailed,
  /** Счёт привязан успешно */
  ACCOUNT_LINKED_SUCCESS,
  /** Оплата и привязка счёта прошли успешно */
  PaymentAndBindingSuccess,
}

export const NEW_ACCOUNT = 'Новый счёт СБП';

/** Query параметры результата платежа */
export enum PAYMENT_QP {
  /** Номер контракта */
  contractNumber = 'contractNumber',
  /** id контракта */
  contractId = 'contractId',
  linkedAccount = 'linkedAccount',
  /** id qr */
  qrId = 'qrId',
  /** Корректная оплата */
  isCorrect = 'isCorrect',
  /** Привязанный счёт */
  isLinkedAccount = 'isLinkedAccount',
  /** id оплаты с карты */
  planetaOrderId = 'planetaOrderId',
  /** Оплата с привязкой карты */
  hasCardBinding = 'hasCardBinding',
  /**  */
  isRememberPaymentData = 'isRememberPaymentData',
  /** id подписки сбп */
  sbpSubscriptionId = 'sbpSubscriptionId',
  /** id привязанной карты */
  linkedCardValue = 'linkedCardValue',
  /** Сумма оплаты */
  amount = 'amount',
}
