/** libraries */
import { Instance, types } from 'mobx-state-tree';
import RouterContext from 'next/router';
/** constants */
import { DEMAND_QP } from '~/components/Blocks/Templates/Pab2c/Help/constants';
import {
  DEFAULT_ERROR,
  ERROR_MESSAGE,
  SIDE_PAGES,
  STATE_SP,
} from '~/components/AuthWizard/constants';

const ExtensionModel = types.model('ExtensionModel', {
  Message: (types.string, ''),
  Limit: types.maybe(types.maybeNull(types.number)),
  Remains: types.maybe(types.maybeNull(types.number)),
  BlockMinutes: types.maybe(types.maybeNull(types.number)),
  ContractName: types.maybe(types.string),
});

export const MakeAuthStore = types
  .model('MakeAuthStore', {
    serverError: (types.string, ''),
    serverExtensionsError: types.maybeNull(ExtensionModel),
    password: (types.string, ''),
    login: (types.string, ''),
    errorLogin: (types.boolean, false),
    errorPassword: (types.boolean, false),
    isLoading: (types.boolean, false),
    isVisible: (types.boolean, false),
    openSPAfterAuthorizationState: types.maybeNull(
      types.frozen<Record<SIDE_PAGES, STATE_SP>>(),
    ),
  })
  .actions((self) => ({
    setLogin: (login) => {
      self.login = login;
    },
    setPassword: (password) => {
      self.password = password;
    },
    setServerExtensionsError: (serverExtensionsError) => {
      self.serverExtensionsError = serverExtensionsError;
    },
    setServerError: (serverError) => {
      self.serverError = serverError;
    },
    setIsLoading: (isLoading) => {
      self.isLoading = isLoading;
    },
    setIsVisible: (isVisible) => {
      self.isVisible = isVisible;
    },
    setOpenSPAfterAuthorizationState: (openSPAfterAuthorizationState) => {
      self.openSPAfterAuthorizationState = openSPAfterAuthorizationState;
    },
    /** Открывает/закрывает сайд пейдж */
    toggleVisible: (openSPAfterAuthorizationState?) => {
      self.isVisible = !self.isVisible;
      if (openSPAfterAuthorizationState)
        self.openSPAfterAuthorizationState = openSPAfterAuthorizationState;
    },
    /** Обработка закрытия виджета в SidePage */
    onCloseClickSidePageAuth: (): void => {
      self.serverError = '';
      self.serverExtensionsError = null;
      self.password = '';
      self.login = '';
      self.errorLogin = false;
      self.errorPassword = false;
      self.isLoading = false;
      self.isVisible = false;

      const qP = { ...RouterContext.router?.query };
      delete qP?.showAutopayment;
      delete qP?.[DEMAND_QP];

      RouterContext.router.replace(
        {
          query: { ...qP },
        },
        undefined,
        {
          shallow: true,
        },
      );
    },
    validateLogin: (): void => {
      self.errorLogin = self.login.length < 4 || self.login.length > 10;
    },
    validatePassword: (): void => {
      self.errorPassword = self.login.length < 6;
    },
    // обработка ошибок
    validation: (res) => {
      self.serverError =
        res.Type in ERROR_MESSAGE
          ? ERROR_MESSAGE[res.Type]
          : ERROR_MESSAGE[DEFAULT_ERROR];
      self.isLoading = false;
    },
    resetErrors: () => {
      if (self.errorLogin) self.errorLogin = false;
      if (self.errorPassword) self.errorPassword = false;
      if (self.serverError) self.serverError = '';
      if (self.serverExtensionsError) self.serverExtensionsError = null;
    },
  }));

export default MakeAuthStore;
export type IMakeAuthStore = Instance<typeof MakeAuthStore>;
